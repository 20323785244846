<template>
  <div class="col-12 text-center">
    <br><br><br>
    قريباً...
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>